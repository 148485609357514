import React from "react";
import { MASS_UNITS, VOLUME_UNITS } from "../../constants";

const IngredientsForm = ({
  data,
  ingredientAmounts,
  handleSelectChange,
  allItems,
  selectedUnits,
  conversionRates,
  setIngredientAmounts,
  handleUnitChange,
  handleRemoveItem,
  handleAddIngredient,
  ingredientUnits,
  handleIngredientUnitChange,
  handleIngredientWeightUnitChange,
  selectedIngredientUnits,
  setIngredientUnits,
  handleRemoveUnit,
  handleAddUnit,
  ingredientVolumeInputs,
  handleInput,
}) => (
  <div>
    INGREDIENT FORM
    <div>
      {data.volume ? (
        <>
          {ingredientVolumeInputs.map((input) => (
            <div className="formInput" key={input.id}>
              <label htmlFor={input.id}>{input.label}</label>
              <input
                id={input.id}
                type={input.type}
                step="any"
                placeholder={input.placeholder}
                onChange={handleInput}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <h3>Ingredients:</h3>
          {Array.isArray(ingredientAmounts) ? (
            <>
              {ingredientAmounts.map((ingredientAmount, index) => (
                <div key={index}>
                  <select
                    data-key="ingredients"
                    onChange={(e) =>
                      handleSelectChange(
                        e,
                        index,
                        ingredientAmounts,
                        allItems.find(
                          (ingredient) => ingredient.id === e.target.value
                        )
                      )
                    }
                    value={ingredientAmount.id}
                  >
                    <option value="">Select Ingredient</option>
                    {allItems.map((ingredient, index) => (
                      <option key={index} value={ingredient.id}>
                        {ingredient.name} ({ingredient.collection})
                      </option>
                    ))}
                  </select>

                  {ingredientAmount.id !== "" ? (
                    <>
                      <input
                        type="number"
                        value={
                          selectedUnits[index].isVolume
                            ? ingredientAmount.amount /
                              selectedUnits[index].unit.amount /
                              conversionRates[index]
                            : ingredientAmount.amount /
                              selectedUnits[index].unit.amount
                        }
                        onChange={(e) => {
                          const newIngredientAmounts = [...ingredientAmounts];
                          if (selectedUnits[index].isVolume) {
                            newIngredientAmounts[index].amount =
                              e.target.value *
                              selectedUnits[index].unit.amount *
                              conversionRates[index];
                          } else {
                            newIngredientAmounts[index].amount =
                              e.target.value * selectedUnits[index].unit.amount;
                          }

                          setIngredientAmounts(newIngredientAmounts);
                        }}
                        placeholder="Amount"
                      />{" "}
                      {allItems.find((item) => item.id === ingredientAmount.id)
                        .volume ? (
                        <select onChange={(e) => handleUnitChange(e, index)}>
                          {MASS_UNITS.map((unit, index) => (
                            <option key={index} value={unit.abbreviation}>
                              {unit.abbreviation}
                            </option>
                          ))}
                          {VOLUME_UNITS.map((unit, index) => (
                            <option key={index} value={unit.abbreviation}>
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select onChange={(e) => handleUnitChange(e, index)}>
                          {MASS_UNITS.map((unit, index) => (
                            <option key={index} value={unit.abbreviation}>
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      )}{" "}
                    </>
                  ) : (
                    <>{"  "}</>
                  )}
                  <button type="button" onClick={() => handleRemoveItem(index)}>
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" onClick={handleAddIngredient}>
                Add Ingredient
              </button>
            </>
          ) : null}
        </>
      )}
    </div>
    {console.log(
      ingredientUnits,
      " selectedIngredientUnits",
      selectedIngredientUnits
    )}
    <div>
      {selectedIngredientUnits ? (
        <>
          <h3>Container Units:</h3>
          {ingredientUnits.map((unit, index) => (
            <div key={index}>
              <input
                type="text"
                value={unit.name}
                placeholder="Unit Name"
                onChange={(e) => handleIngredientUnitChange(e, index, "name")}
              />
              <input
                type="text"
                value={unit.sku}
                placeholder="SKU"
                onChange={(e) => handleIngredientUnitChange(e, index, "sku")}
              />
              <input
                type="number"
                value={
                  VOLUME_UNITS.find(
                    (unit) =>
                      unit.abbreviation ===
                      selectedIngredientUnits[index].abbreviation
                  )
                    ? unit.unitWeight /
                      data.massconversion /
                      selectedIngredientUnits[index].amount
                    : unit.unitWeight / selectedIngredientUnits[index].amount
                }
                onChange={(e) => {
                  handleIngredientUnitChange(e, index, "unitWeight");
                }}
                placeholder="Weight"
              />

              {data.volume ? (
                <select
                  onChange={(e) => handleIngredientWeightUnitChange(e, index)}
                >
                  {MASS_UNITS.map((unit, index) => (
                    <option key={index} value={unit.abbreviation}>
                      {unit.abbreviation}
                    </option>
                  ))}
                  {VOLUME_UNITS.map((unit, index) => (
                    <option key={index} value={unit.abbreviation}>
                      {unit.abbreviation}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={(e) => handleIngredientWeightUnitChange(e, index)}
                >
                  {MASS_UNITS.map((unit, index) => (
                    <option key={index} value={unit.abbreviation}>
                      {unit.abbreviation}
                    </option>
                  ))}
                </select>
              )}

              <button type="button" onClick={() => handleRemoveUnit(index)}>
                Remove
              </button>
            </div>
          ))}
          <button type="button" onClick={handleAddUnit}>
            Add Unit
          </button>
        </>
      ) : null}
    </div>
    end of form
  </div>
);

export default IngredientsForm;

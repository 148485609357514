import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import React, { Fragment, useEffect, useState } from "react";
import "./home.scss";
import InventoryForm from "../../components/inventoryForm/inventoryForm";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import { useContext } from "react";
import {
  doc,
  getDoc,
  updateDoc, // Import updateDoc for editing functionality
  onSnapshot,
  query,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

const Home = () => {
  const [ingredients, setIngredients] = useState([]);
  const [doughs, setDoughs] = useState([]);
  const [products, setProducts] = useState([]);
  const [lines, setLines] = useState([]);
  const { selected } = useContext(OrganizationsContext);
  const organizationId = JSON.parse(localStorage.getItem("organizations"))
    .selected.id;

  const fetchItems = (collectionName, stateUpdater) => {
    return onSnapshot(
      query(
        collection(db, collectionName),
        where("organizationId", "==", organizationId)
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: collectionName + "/" + doc.id,
          collection: collectionName,
          ...doc.data(),
        }));
        stateUpdater(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const unsubscribeIngredients = fetchItems("ingredients", setIngredients);
    const unsubscribeDoughs = fetchItems("doughs", setDoughs);
    const unsubscribeProducts = fetchItems("products", setProducts);

    return () => {
      unsubscribeIngredients();
      unsubscribeDoughs();
      unsubscribeProducts();

    };
  }, [organizationId]);

  const allItems = [...ingredients, ...doughs, ...products];
  console.log("allItems ", allItems)
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        {selected ? <h2>{selected.name} dashboard</h2> : null}
        <h4>Dashboard with analytics coming soon... </h4>

        {/* <InventoryForm allItems={allItems} /> */}
        {/*         
        <div className="widgets">
          <Widget type="user" />
          <Widget type="product" />
          {
          //<Widget type="order" />
          //<Widget type="earning" />
          //<Widget type="balance" /> 
         }
        </div>
        <div className="charts">
          <Featured />
          {//<Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
          }
        </div>
        <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
